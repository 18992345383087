<template>
	<div>
		<scNav navTag="scNews" :themeColor="themeColor"></scNav>
		<!-- 面包屑 -->
		<div style="max-width: 1440px;margin: 0 auto;">
			<crumbs :crumbs="['首页','新闻中心']" @clkItem="clkItem"></crumbs>
		</div>
		<div class="sc-width">
			<tap :taps="taps" :bgColor="themeColor"><i></i></tap>
			<template v-if="policy.data.length">
				<newList @clkCup="clkCup" :themeColor="themeColor" :policy="policy.data"></newList>
				<div class="block">
					<el-pagination
						background
						:page-size="parseInt(policy.per_page)"
						@current-change="downPage"
						layout="total, prev, pager, next, jumper"
						:total="policy.total">
					</el-pagination>
				</div>
				<phonePg :page="page" :total="policy.total" :perPage="parseInt(policy.per_page)" @pageNext="pageNext" @pagePref="pagePref"></phonePg>
			</template>
			<template v-else>
				<el-empty description="暂无数据"></el-empty>
			</template>
		</div>
		<bottom :bgColor="themeColor"></bottom>
	</div>
</template>

<script>
	import newList from "@/components/new/newList"
	import scNav from '@/components/special/scNav'
	import phonePg from '@/components/phonePg/phonePg'
	export default {
		components:{
			newList,
			scNav,
			phonePg
		},
		data() {
			return {
				taps:['新闻中心'],
				page:1,
				limit:10,
				policy:[],
				themeColor:"",
				catid:0,
				type:1
			}
		},
		created() {
			let type = this.$route.query.type
			this.type = type
			if(type==1) {
				// 专精特新版
				
				this.themeColor = "#386eb8"
				this.catid = 120
			}else if(type==2) {
				// 科技创新版	
				this.themeColor = "#47a8c8"
				this.catid = 129
			}else if(type==3) {
				// 交易版
				this.themeColor = "#d3a21e"
				this.catid = 137
			}
			this.getNewList(type)
		},
		methods:{
			clkItem(index) {
				let type = this.$route.query.type
				switch(index) {
					case 0:
						this.$router.push({name:"scIndex",query:{type}})
					break;
				}
			},
			clkCup(index,id) {
				let type = this.$route.query.type
				this.$router.push({name:"scNewsdeal",query:{type,id}})
			},
			// 获取新闻列表
			async getNewList(type) {
				let url = ""
				if(type==1) {
					url = this.$api.base+"Specialization/getList"
				}else if(type==2) {
					url = this.$api.base+"Technology/getList"
				}else if(type==3) {
					url = this.$api.base+"Trading/getList"
				}
				let params = {
					catid:this.catid,
					limit:this.limit,
					page:this.page
				}
				let res = await this.$axios.get(url,{params})
				if(res.code) {
					this.policy = res.data
				}
			},
			// 页数变化时触发
			downPage(e) {
				this.page = e
				this.getNewList(this.type)
			},
			
			// 点击上一页
			pageNext() {
				if(!this.page--) {
					return false
				}
				this.page--
				this.getNewList(this.type)
			},
			// 点击下一页
			pagePref() {
				if((this.page+1)*this.policy.per_page>this.policy.total) {
					return false
				}
				this.page ++
				this.getNewList(this.type)
			},
		}
	}
</script>

<style>
	
</style>